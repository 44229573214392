"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    tableBodyStyle: function() {
        return tableBodyStyle;
    },
    tableCaptionStyle: function() {
        return tableCaptionStyle;
    },
    tableCellContentStyle: function() {
        return tableCellContentStyle;
    },
    tableCellStyle: function() {
        return tableCellStyle;
    },
    tableFooterStyle: function() {
        return tableFooterStyle;
    },
    tableHeadContentStyle: function() {
        return tableHeadContentStyle;
    },
    tableHeadStyle: function() {
        return tableHeadStyle;
    },
    tableHeaderStyle: function() {
        return tableHeaderStyle;
    },
    tableRowStyleStyle: function() {
        return tableRowStyleStyle;
    },
    tableStyle: function() {
        return tableStyle;
    },
    tableWrapperStyle: function() {
        return tableWrapperStyle;
    }
});
const _tva = require("@gluestack-ui/nativewind-utils/tva");
const captionTableStyle = process.env.IS_WEB ? "caption-bottom" : "";
const tableWrapperStyle = (0, _tva.tva)({
    base: "bg-white max-w-full overflow-auto",
    variants: {
        visible: {
            true: "overflow-visible"
        }
    }
});
const tableStyle = (0, _tva.tva)({
    base: "table w-full border-collapse",
    variants: {
        useGridTemplate: {
            true: "grid"
        }
    }
});
const tableHeaderStyle = (0, _tva.tva)({
    base: ""
});
const tableBodyStyle = (0, _tva.tva)({
    base: ""
});
const tableFooterStyle = (0, _tva.tva)({
    base: ""
});
const tableHeadStyle = (0, _tva.tva)({
    base: "table-cell flex-1 align-middle justify-center bg-blueGray-50 first:rounded-tl-lg last:rounded-tr-lg",
    variants: {
        size: {
            sm: "px-1.5 py-2",
            md: "px-4 py-3"
        },
        useGridTemplate: {
            true: "flex"
        }
    }
});
const tableHeadContentStyle = (0, _tva.tva)({
    base: "",
    variants: {
        align: {
            start: "items-start",
            center: "items-center",
            end: "items-end"
        }
    }
});
const tableRowStyleStyle = (0, _tva.tva)({
    base: "table-row w-full group",
    variants: {
        type: {
            footer: "",
            header: ""
        },
        isFooterRow: {
            true: "border-b-0 "
        },
        useGridTemplate: {
            true: "contents"
        }
    }
});
const tableCellStyle = (0, _tva.tva)({
    base: `
        flex-1
        table-cell 
        border-b 
        border-solid 
        border-blueGray-200 
        align-middle 
        justify-center
        group-hover:bg-blueGray-50
    `,
    variants: {
        size: {
            sm: "px-1.5 py-2",
            md: "px-4 py-4"
        },
        useGridTemplate: {
            true: "flex"
        }
    }
});
const tableCellContentStyle = (0, _tva.tva)({
    base: "",
    variants: {
        align: {
            start: "items-start",
            center: "items-center",
            end: "items-end"
        },
        visibleOnHover: {
            true: 'opacity-0 group-hover:opacity-100 web:has-[[aria-expanded="true"]]:opacity-100 web:[@media(hover:none)]:opacity-100'
        }
    }
});
const tableCaptionStyle = (0, _tva.tva)({
    base: `${captionTableStyle} px-6 py-[14px] text-[16px] font-normal leading-[22px] text-typography-800 bg-background-50 font-roboto`
});
