"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useMobileView", {
    enumerable: true,
    get: function() {
        return useMobileView;
    }
});
const _react = require("react");
function useMobileView() {
    const [isMobile, setIsMobile] = (0, _react.useState)(()=>{
        if (!process.env.IS_WEB) {
            return false;
        }
        const mediaQuery = window.matchMedia("(max-width: 768px)");
        return mediaQuery.matches;
    });
    (0, _react.useEffect)(()=>{
        if (!process.env.IS_WEB) {
            return;
        }
        const mediaQuery = window.matchMedia("(max-width: 768px)"); // Match the `mobile` breakpoint
        const handleResize = ()=>setIsMobile(mediaQuery.matches);
        handleResize();
        mediaQuery.addEventListener("change", handleResize);
        return ()=>mediaQuery.removeEventListener("change", handleResize);
    }, []);
    return isMobile;
}
