"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    alertContainerStyle: function() {
        return alertContainerStyle;
    },
    alertIconStyle: function() {
        return alertIconStyle;
    },
    alertStyle: function() {
        return alertStyle;
    },
    alertTextStyle: function() {
        return alertTextStyle;
    },
    iconWrapperInsideStyle: function() {
        return iconWrapperInsideStyle;
    },
    iconWrapperOutsideStyle: function() {
        return iconWrapperOutsideStyle;
    }
});
const _tva = require("@gluestack-ui/nativewind-utils/tva");
const alertStyle = (0, _tva.tva)({
    base: "items-center",
    variants: {
        action: {
            error: "",
            warning: "",
            success: "",
            info: "",
            muted: ""
        },
        variant: {
            solid: "border-b border-solid py-3 px-6 flex-row gap-2",
            subtle: "p-0 bg-transparent border-b-0 gap-1.5"
        }
    },
    compoundVariants: [
        {
            action: "error",
            variant: "solid",
            class: "bg-error-50 border-b-error-300"
        },
        {
            action: "warning",
            variant: "solid",
            class: "bg-warning-50 border-b-warning-300"
        },
        {
            action: "success",
            variant: "solid",
            class: "bg-success-50 border-b-success-300"
        },
        {
            action: "info",
            variant: "solid",
            class: "bg-blue-50 border-b-blue-300"
        },
        {
            action: "muted",
            variant: "solid",
            class: "bg-blueGray-50 border-b-blueGray-300"
        }
    ]
});
const alertTextStyle = (0, _tva.tva)({
    base: "",
    variants: {},
    parentVariants: {
        action: {
            error: "text-error-600",
            warning: "text-warning-600",
            success: "text-success-600",
            info: "text-blue-600",
            muted: "text-blueGray-600"
        }
    }
});
const alertIconStyle = (0, _tva.tva)({
    base: "",
    variants: {},
    parentVariants: {
        variant: {
            subtle: "mt-0.5",
            solid: ""
        },
        action: {
            error: "fill-error-600",
            warning: "fill-warning-600",
            success: "fill-success-600",
            info: "fill-blue-600",
            muted: "fill-blueGray-600"
        }
    }
});
const iconWrapperOutsideStyle = (0, _tva.tva)({
    base: "h-10 w-10 flex-shrink-0 rounded-full border-2 border-solid",
    variants: {
        action: {
            muted: "border-blueGray-600/[0.1]",
            success: "border-success-600/[0.1]",
            warning: "border-warning-600/[0.1]",
            error: "border-red-600/[0.1]",
            info: "border-blue-600/[0.1]"
        }
    }
});
const iconWrapperInsideStyle = (0, _tva.tva)({
    base: "h-7 w-7 rounded-full border-2 border-solid",
    variants: {
        action: {
            muted: "border-blueGray-600/[0.3]",
            success: "border-success-600/[0.3]",
            warning: "border-warning-600/[0.3]",
            error: "border-red-600/[0.3]",
            info: "border-blue-600/[0.3]"
        }
    }
});
const alertContainerStyle = (0, _tva.tva)({
    base: "w-full align-start justify-between",
    variants: {
        variant: {
            solid: "gap-3",
            subtle: "gap-2"
        }
    }
});
