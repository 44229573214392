"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useChannelLogoVariant", {
    enumerable: true,
    get: function() {
        return useChannelLogoVariant;
    }
});
const _react = require("react");
function useChannelLogoVariant(size) {
    return (0, _react.useMemo)(()=>{
        const channelLogoVariants = {
            "2xs": {
                logoSizeClassName: "h-2 w-2",
                textSize: "xs",
                space: "xs"
            },
            xs: {
                logoSizeClassName: "h-3 w-3",
                textSize: "xs",
                space: "xs"
            },
            sm: {
                logoSizeClassName: "h-4 w-4",
                textSize: "sm",
                space: "xs"
            },
            md: {
                logoSizeClassName: "h-5 w-5",
                textSize: "md",
                space: "xs"
            },
            lg: {
                logoSizeClassName: "h-6 w-6",
                textSize: "lg",
                space: "xs"
            },
            xl: {
                logoSizeClassName: "h-8 w-8",
                textSize: "lg",
                space: "xs"
            },
            "2xl": {
                logoSizeClassName: "h-9 w-9",
                textSize: "lg",
                space: "xs"
            },
            "3xl": {
                logoSizeClassName: "h-12 w-12",
                textSize: "lg",
                space: "xs"
            }
        };
        return channelLogoVariants[size];
    }, [
        size
    ]);
}
