"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "formatPhoneNumber", {
    enumerable: true,
    get: function() {
        return formatPhoneNumber;
    }
});
function formatPhoneNumber(str) {
    const cleaned = `${str}`.replace(/\D/g, "");
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        // Remove the matched extension code
        // Change this to format for any country code.
        const intlCode = match[1] ? "+1 " : "";
        return [
            intlCode,
            "(",
            match[2],
            ") ",
            match[3],
            "-",
            match[4]
        ].join("");
    }
    return `${str}`;
}
