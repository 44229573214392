"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    DATE_FORMAT: function() {
        return DATE_FORMAT;
    },
    eventClassNames: function() {
        return eventClassNames;
    },
    generateKeyOfWeekEvent: function() {
        return generateKeyOfWeekEvent;
    },
    getCalendarApi: function() {
        return getCalendarApi;
    },
    getNameFromReservation: function() {
        return getNameFromReservation;
    },
    getNumberOfLineOfEvent: function() {
        return getNumberOfLineOfEvent;
    },
    getPricesRange: function() {
        return getPricesRange;
    },
    isSelectDateOccupied: function() {
        return isSelectDateOccupied;
    }
});
const _utilsreservation = require("../../../../core/src/shared/utils/utils.reservation");
const _datefns = require("date-fns");
const _date = require("./date");
const DATE_FORMAT = "yyyy-MM-dd";
const getCalendarApi = (ref)=>{
    const { current } = ref;
    return current === null || current === void 0 ? void 0 : current.getApi();
};
function eventClassNames(params) {
    const { event, isStart, isEnd } = params;
    const { wrapperClass } = event.extendedProps;
    let classNames = `${wrapperClass} border-0`;
    if (isStart && isEnd) {
        classNames += " event-full";
    }
    if (!isStart) {
        classNames += " event-without-start";
    }
    if (!isEnd) {
        classNames += " event-without-end";
    }
    return classNames;
}
function getNameFromReservation(reservation) {
    const { source } = reservation;
    const fullName = (0, _utilsreservation.getFullName)(reservation);
    return fullName || source;
}
function generateKeyOfWeekEvent(id, weekDate) {
    return `${id}_${weekDate.getMonth()}_${weekDate.getDate()}`;
}
function getNumberOfLineOfEvent({ info, lineOfEventsMounted, weekStartsOn, reservations }) {
    const { isStart, isEnd, event } = info;
    const { id, extendedProps: { startDate: eventStartDate, endDate: eventEndDate } } = event;
    let weekIsRendering;
    const listWeeks = (0, _datefns.eachWeekOfInterval)({
        start: eventStartDate,
        end: eventEndDate
    }, {
        weekStartsOn
    });
    if (!isStart && isEnd) {
        weekIsRendering = (0, _datefns.startOfWeek)(eventEndDate, {
            weekStartsOn
        });
    } else if (isStart && !isEnd) {
        weekIsRendering = (0, _datefns.startOfWeek)(eventStartDate, {
            weekStartsOn
        });
    } else if (isStart && isEnd) {
        weekIsRendering = (0, _datefns.startOfWeek)(eventStartDate, {
            weekStartsOn
        });
    } else {
        // this case when event has no head and tail, we dont know what part of event, so we find the part by skip parts mounted before.
        weekIsRendering = listWeeks.slice(1, listWeeks.length - 1).find((w)=>lineOfEventsMounted[generateKeyOfWeekEvent(id, w)] === undefined);
    }
    if (!weekIsRendering) {
        return {
            lineOfEventShouldPlace: 0
        };
    }
    const beforeEvents = reservations.slice(0, reservations.findIndex((r)=>r.id === id));
    let checkDate = eventStartDate;
    if (!isStart && isEnd) {
        checkDate = (0, _datefns.startOfWeek)(eventEndDate, {
            weekStartsOn
        });
    } else if (!isStart && !isEnd) {
        checkDate = weekIsRendering;
    }
    const eventsOverlap = beforeEvents.filter((r)=>{
        return (0, _datefns.isWithinInterval)(checkDate, {
            start: r.startDate,
            end: r.endDate
        }) && !(0, _datefns.isSameDay)(eventStartDate, r.endDate);
    });
    // Use number of line of events overlap which has same week of this event(same week mean same  row)
    // to find out whether has empty place above this event.
    const lineEmptyAboveThisEvent = eventsOverlap.map((e)=>lineOfEventsMounted[generateKeyOfWeekEvent(e.id, weekIsRendering)]).reduce((arr, e)=>{
        arr[e] = 1;
        return arr;
    }, []).findIndex((e)=>!e);
    // if no line empty above this event, this event should place below events overlap it
    const lineOfEventShouldPlace = lineEmptyAboveThisEvent !== -1 ? lineEmptyAboveThisEvent : eventsOverlap.length;
    return {
        lineOfEventShouldPlace,
        weekIsRendering
    };
}
function isSelectDateOccupied(start, end, events) {
    const isOccupied = events.some((event)=>{
        if (!event.start || !event.end) {
            return false;
        }
        const eventDates = {
            start: event.start,
            end: (0, _datefns.isSameDay)(event.start, event.end) ? event.end : (0, _datefns.subDays)(event.end, 1)
        };
        const selectionIncludesReservation = (0, _datefns.isBefore)(start, eventDates.start) && (0, _datefns.isAfter)(end, eventDates.end);
        if (selectionIncludesReservation) {
            return true;
        }
        const startDateIsWithinReservation = (0, _datefns.isWithinInterval)(start, eventDates);
        if (startDateIsWithinReservation) {
            return true;
        }
        const endDateIsWithinReservation = (0, _datefns.isWithinInterval)(end, eventDates) && !(0, _datefns.isSameDay)(end, eventDates.start);
        return endDateIsWithinReservation;
    });
    return isOccupied;
}
function getPricesRange(prices, start, end) {
    return (0, _datefns.eachDayOfInterval)({
        start,
        end
    }).map((date)=>prices[(0, _date.formatyyyyMMdd)(date)]).filter(Boolean);
}
