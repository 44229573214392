"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    generateDateTextByKey: function() {
        return generateDateTextByKey;
    },
    getPriceChannels: function() {
        return getPriceChannels;
    }
});
const _lodash = require("lodash");
const getPriceChannels = (price, channels)=>{
    return channels.reduce((result, channel)=>{
        const priceChannel = price[channel];
        if (priceChannel) {
            result.push(priceChannel);
        }
        return result;
    }, []);
};
const generateDateTextByKey = (priceList, ...[key, keyDisplay])=>{
    const sortedList = (0, _lodash.uniq)((0, _lodash.sortBy)(priceList, key).reduce((result, price)=>{
        const value = price === null || price === void 0 ? void 0 : price[keyDisplay];
        if (typeof value !== "undefined") {
            result.push(value);
        }
        return result;
    }, []));
    const first = sortedList[0];
    const last = sortedList.length > 1 ? sortedList[sortedList.length - 1] : undefined;
    if (typeof first === "undefined") {
        return "-";
    }
    if (typeof last === "undefined") {
        return `${first}`;
    }
    return `${first}-${last}`;
};
