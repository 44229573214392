"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "headingStyle", {
    enumerable: true,
    get: function() {
        return headingStyle;
    }
});
const _IsWeb = require("@gluestack-ui/nativewind-utils/IsWeb");
const _tva = require("@gluestack-ui/nativewind-utils/tva");
const baseStyle = _IsWeb.isWeb ? "list-none text-start no-underline whitespace-pre-wrap word-wrap-break-word box-border" : "";
const headingStyle = (0, _tva.tva)({
    base: `font-bold leading-sm font-heading tracking-md ${baseStyle}`,
    variants: {
        variant: {
            lg: "text-2xl",
            xl: "text-[32px]",
            "2xl": "text-[36px]",
            "3xl": "text-[48px] tracking-xl"
        },
        color: {
            "success.600": "text-success-600",
            "warning.600": "text-warning-600",
            "danger.600": "text-danger-600",
            "red.600": "text-red-600",
            "blueGray.400": "text-blueGray-400",
            "blueGray.600": "text-blueGray-600",
            "blueGray.800": "text-blueGray-800",
            "blue.300": "text-blue-300",
            "blue.600": "text-blue-600",
            "blue.900": "text-blue-900",
            white: "text-white",
            HomeAway: "text-HomeAway-text",
            Airbnb: "text-Airbnb-text",
            Booking: "text-Booking-text",
            Houfy: "text-Houfy-text",
            internal: "text-internal-text"
        },
        isTruncated: {
            true: "truncate"
        },
        bold: {
            true: "font-bold"
        },
        underline: {
            true: "underline"
        },
        strikeThrough: {
            true: "line-through"
        },
        sub: {
            true: "text-xs"
        },
        italic: {
            true: "italic"
        },
        highlight: {
            true: "bg-yellow-500"
        },
        numberOfLines: {
            1: "line-clamp-1",
            2: "line-clamp-2"
        },
        maxW: {
            full: "max-w-full",
            "50%": "max-w-0.5"
        },
        textTransform: {
            uppercase: "uppercase",
            lowercase: "lowercase",
            capitalize: "capitalize"
        }
    }
});
