"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "formatCCCDDMMMYYYY", {
    enumerable: true,
    get: function() {
        return formatCCCDDMMMYYYY;
    }
});
const _date = require("../../utils/date");
function formatCCCDDMMMYYYY(date) {
    const formatPattern = "ccc dd MMM, yyyy";
    return (0, _date.formatDate)(date, formatPattern);
}
