"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
class PricingService {
    async getPrices(listingID, starting) {
        const url = `/getPrices/${listingID}/${starting}`;
        const { data } = await this.http.get(url);
        return data;
    }
    async getAirbticsData(listingID) {
        const url = `/getAirbtics/${listingID}`;
        const { data } = await this.http.get(url);
        return data;
    }
    async setPriceOverride(startDate, endDate, listingID, prices, allPrice) {
        const url = "/setPriceOverride";
        const pricePayload = allPrice ? {
            all: {
                price: allPrice
            }
        } : Object.keys(prices).reduce((arr, channel)=>{
            var _prices_channel;
            if ((_prices_channel = prices[channel]) === null || _prices_channel === void 0 ? void 0 : _prices_channel.priceOverride) {
                arr[channel] = {
                    price: prices[channel].priceOverride
                };
            }
            return arr;
        }, {});
        const { data } = await this.http.post(url, {
            startDate,
            endDate,
            listingID,
            prices: pricePayload
        });
        return data;
    }
    async overrideAvailability(payload) {
        const url = "/setAvailability";
        const { data } = await this.http.post(url, payload);
        return data;
    }
    constructor(http){
        _define_property(this, "http", void 0);
        this.http = http;
    }
}
const _default = PricingService;
