"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _default = {
    booking: {
        allListings: true,
        autoAdd: true,
        title: "Booking Confirmation Rule",
        message: "Hi {{Guest First Name}},\n\n" + "Thanks for booking our place.  I'll send you more details including check-in instructions soon but " + "in the meantime, if you have any questions please don't hesitate to ask.",
        event: "booking",
        lastMinuteMessageEnabled: false,
        lastMinuteMessageIsTheSame: false,
        lastMinuteMessage: "",
        reviewEnabled: false,
        reviewMessage: "",
        sendMessageAfterLeavingReview: false,
        disableMessageAfterReview: false,
        emailEnabled: false,
        email: "",
        smsEnabled: false,
        sms: "",
        delay: 10,
        preapprove: false,
        languagesEnabled: false
    },
    inquiry: {
        allListings: true,
        autoAdd: true,
        title: "Inquiry Response Rule",
        message: "Hi {{Guest First Name}},\n\n" + "Thanks for your inquiry, I wanted to let you know that I got your message and will respond right away.",
        event: "inquiry",
        lastMinuteMessageEnabled: false,
        lastMinuteMessageIsTheSame: false,
        lastMinuteMessage: "",
        reviewEnabled: false,
        reviewMessage: "",
        sendMessageAfterLeavingReview: false,
        disableMessageAfterReview: false,
        emailEnabled: false,
        email: "",
        smsEnabled: false,
        sms: "",
        delay: 10,
        preapprove: false,
        languagesEnabled: false
    },
    timedout: {
        allListings: true,
        autoAdd: true,
        title: "Booking Pre-approval Expired Rule",
        message: "Hi {{Guest First Name}},\n\n" + "I saw that your pre-approval expired and wanted to let you know that we would be happy to host you if you decide to book.  Feel free to send me a message if you have any questions.",
        event: "timedout",
        lastMinuteMessageEnabled: false,
        lastMinuteMessageIsTheSame: false,
        lastMinuteMessage: "",
        reviewEnabled: false,
        reviewMessage: "",
        sendMessageAfterLeavingReview: false,
        disableMessageAfterReview: false,
        emailEnabled: false,
        email: "",
        smsEnabled: false,
        sms: "",
        delay: 10,
        preapprove: false,
        languagesEnabled: false
    },
    pending: {
        allListings: true,
        autoAdd: true,
        title: "Booking Request Response Rule",
        message: "Hi {{Guest First Name}},\n\n" + "Thanks for booking request, I wanted to let you know that I received your message and I will respond right away.",
        event: "pending",
        lastMinuteMessageEnabled: false,
        lastMinuteMessageIsTheSame: false,
        lastMinuteMessage: "",
        reviewEnabled: false,
        reviewMessage: "",
        sendMessageAfterLeavingReview: false,
        disableMessageAfterReview: false,
        emailEnabled: false,
        email: "",
        smsEnabled: false,
        sms: "",
        delay: 10,
        preapprove: false,
        languagesEnabled: false
    },
    checkin: {
        allListings: false,
        autoAdd: true,
        title: "Check-In Rule",
        minutes: 1020,
        days: -2,
        event: "checkin",
        reservationLength: 1,
        message: "Hi {{Guest First Name}},\n\n" + "Just wanted to touch base and give you some more information about your stay.  You are welcome " + "to check-in anytime after {{Check-In Time}} {{Check-In Date}}.  Your checkout time " + "is {{Check-Out Time}} {{Check-Out Date}}.\n\n" + "LOCATION:\n" + "{{Listing Address}}\n\n" + "KEY:\n" + "There is a keypad attached to the front door. Your access code is {{Guest Lock Code}}. Please note that " + "this code is not active until your scheduled check-in time.\n\n" + "PARKING:\n" + "You're welcome to park in the drive way and there is also always plenty of free street parking.\n\n" + "WIFI:\n" + "The wifi network is 'WIFI_NETWORK_NAME' and the password is 'WIFI_PASSWORD'.\n\n" + "More information about the apartment and neighborhood can be found in the house manual.\n\n" + "Please let us know if you have any questions and I hope you enjoy your stay.",
        lastMinuteMessageEnabled: true,
        lastMinuteMessageIsTheSame: false,
        lastMinuteMessage: "Hi {{Guest First Name}},\n\n" + "Thanks for booking our place.  You are welcome " + "to check-in anytime after {{Check-In Time}} {{Check-In Date}}.  Your checkout time " + "is {{Check-Out Time}} {{Check-Out Date}}.\n\n" + "LOCATION:\n" + "{{Listing Address}}\n\n" + "KEY:\n" + "There is a keypad attached to the front door. Your access code is {{Guest Lock Code}}. Please note that " + "this code is not active until your scheduled check-in time.\n\n" + "PARKING:\n" + "You're welcome to park in the drive way and there is also always plenty of free street parking.\n\n" + "WIFI:\n" + "The wifi network is 'WIFI_NETWORK_NAME' and the password is 'WIFI_PASSWORD'.\n\n" + "More information about the apartment and neighborhood can be found in the house manual.\n\n" + "Please let us know if you have any questions and I hope you enjoy your stay.",
        reviewEnabled: false,
        reviewMessage: "",
        sendMessageAfterLeavingReview: false,
        disableMessageAfterReview: false,
        emailEnabled: false,
        email: "",
        smsEnabled: false,
        sms: "",
        delay: 0,
        preapprove: false,
        languagesEnabled: false
    },
    checkup: {
        allListings: true,
        autoAdd: true,
        title: "Check-Up Rule",
        minutes: 600,
        days: 1,
        event: "checkin",
        reservationLength: 2,
        message: "Hi {{Guest First Name}},\n\n" + "Just wanted to check in and make sure you have everything you need?\n\n" + "Hope you're enjoying your stay!",
        lastMinuteMessageEnabled: false,
        lastMinuteMessageIsTheSame: false,
        lastMinuteMessage: "",
        reviewEnabled: false,
        reviewMessage: "",
        sendMessageAfterLeavingReview: false,
        disableMessageAfterReview: false,
        emailEnabled: false,
        email: "",
        smsEnabled: false,
        sms: "",
        delay: 0,
        preapprove: false,
        languagesEnabled: false
    },
    checkout: {
        allListings: true,
        autoAdd: true,
        title: "Check-Out Rule",
        minutes: 1020,
        days: -1,
        event: "checkout",
        reservationLength: 2,
        message: "Hi {{Guest First Name}},\n\n" + "Just a reminder that your check-out is tomorrow at {{Check-Out Time}}.\n\n" + "Before you check-out please wash your dishes and put anything back you moved " + "during your visit.  When you are ready to leave please lock the door on your way out.\n\n" + "Hope you had a great time.\n\n" + "Happy travels!",
        lastMinuteMessageEnabled: false,
        lastMinuteMessageIsTheSame: false,
        lastMinuteMessage: "",
        reviewEnabled: false,
        reviewMessage: "",
        sendMessageAfterLeavingReview: false,
        disableMessageAfterReview: false,
        emailEnabled: false,
        email: "",
        smsEnabled: false,
        sms: "",
        delay: 0,
        preapprove: false,
        languagesEnabled: false
    },
    takeOutTheTrash: {
        allListings: true,
        autoAdd: true,
        title: "Please Take Out the Trash",
        minutes: 1080,
        days: -1,
        event: "occupied",
        dayOfTheWeek: "thursday",
        reservationLength: 1,
        message: "Hi {{Guest First Name}},\n\n" + "Could you do me a favor?  The trash gets picked up every Friday morning, would you " + "mind rolling the bin out to the curb tonight?\n\n" + "Thanks!",
        lastMinuteMessageEnabled: false,
        lastMinuteMessageIsTheSame: false,
        lastMinuteMessage: "",
        reviewEnabled: false,
        reviewMessage: "",
        sendMessageAfterLeavingReview: false,
        disableMessageAfterReview: false,
        emailEnabled: false,
        email: "",
        smsEnabled: false,
        sms: "",
        delay: 0,
        preapprove: false,
        languagesEnabled: false
    },
    review: {
        allListings: true,
        autoAdd: true,
        title: "Review Guest Rule",
        minutes: 960,
        days: 1,
        event: "checkout",
        reservationLength: 1,
        message: "Hi {{Guest First Name}},\n\n" + "Thanks for being such a great guest and leaving the place so clean.  " + "We left you a 5 star review and if you enjoyed your stay it would be great if you left us a review as well.  " + "If there is anything that could have made your stay better please send us a message." + "Thanks again for booking our place.\n\n" + "Hope to see you next time you're in town!",
        lastMinuteMessageEnabled: false,
        lastMinuteMessageIsTheSame: false,
        lastMinuteMessage: "",
        reviewEnabled: true,
        reviewMessage: "What a great guest!  Would be happy to host {{Guest First Name}} again anytime!",
        sendMessageAfterLeavingReview: true,
        disableMessageAfterReview: true,
        emailEnabled: false,
        email: "",
        smsEnabled: false,
        sms: "",
        delay: 0,
        preapprove: false,
        languagesEnabled: false
    },
    reviewReminder: {
        allListings: true,
        autoAdd: true,
        title: "Remind Guest to Review Rule",
        minutes: 960,
        days: 10,
        event: "checkout",
        reservationLength: 1,
        message: "Hi {{Guest First Name}},\n\n" + "Sorry to bother you but if you have a second, could you write us a review?  Reviews " + "are very important to us and help us maintain our Superhost status on Airbnb, we'd " + "really appreciate it.",
        lastMinuteMessageEnabled: false,
        lastMinuteMessageIsTheSame: false,
        lastMinuteMessage: "",
        reviewEnabled: false,
        reviewMessage: "",
        sendMessageAfterLeavingReview: false,
        disableMessageAfterReview: true,
        emailEnabled: false,
        email: "",
        smsEnabled: false,
        sms: "",
        delay: 0,
        preapprove: false,
        languagesEnabled: false
    },
    nineMonthReminder: {
        allListings: true,
        autoAdd: true,
        title: "9 Month Marketing Rule",
        minutes: 1020,
        days: 270,
        event: "checkout",
        reservationLength: 1,
        message: "Hi {{Guest First Name}},\n\n" + "I hope you're doing well! It's been about 9 months since you stayed with us, " + "and we were just thinking about what a pleasure it was hosting you. " + "We're reaching out because this time of year tends to book up quickly, " + "and we'd love to host you again!\n\n" + "As a thank you for being such a great guest, we'd like to offer you a 10% discount " + "on your next stay. Just reach out to me directly " + "and I'll help you with the booking and apply the discount.\n\n" + "Looking forward to hopefully hosting you again!",
        lastMinuteMessageEnabled: false,
        lastMinuteMessageIsTheSame: false,
        lastMinuteMessage: "",
        reviewEnabled: false,
        reviewMessage: "",
        sendMessageAfterLeavingReview: false,
        disableMessageAfterReview: false,
        emailEnabled: false,
        email: "",
        smsEnabled: false,
        sms: "",
        delay: 0,
        preapprove: false,
        languagesEnabled: false
    },
    email: {
        allListings: true,
        autoAdd: true,
        title: "Email Rule",
        message: "Hi Cleaning Service,\n\n" + "We just got a new booking.  Can you please clean {{Listing Address}} on {{Check-Out Date}} between {{Check-Out Time}} and {{Check-In Time}}?\n\n" + "Thanks!",
        event: "booking",
        lastMinuteMessageEnabled: false,
        lastMinuteMessageIsTheSame: false,
        lastMinuteMessage: "",
        reviewEnabled: false,
        reviewMessage: "",
        sendMessageAfterLeavingReview: false,
        disableMessageAfterReview: false,
        emailEnabled: true,
        email: "",
        smsEnabled: false,
        sms: "",
        delay: 0,
        preapprove: false,
        languagesEnabled: false
    },
    sms: {
        allListings: true,
        autoAdd: false,
        title: "SMS Rule",
        message: "Hi Cleaning Service,\n\n" + "We just got a new booking.  Can you please clean {{Listing Address}} on {{Check-Out Date}} between {{Check-Out Time}} and {{Check-In Time}}?\n\n" + "Thanks!",
        event: "booking",
        lastMinuteMessageEnabled: false,
        lastMinuteMessageIsTheSame: false,
        lastMinuteMessage: "",
        reviewEnabled: false,
        reviewMessage: "",
        sendMessageAfterLeavingReview: false,
        disableMessageAfterReview: false,
        emailEnabled: false,
        email: "",
        smsEnabled: true,
        sms: "",
        delay: 0,
        preapprove: false,
        languagesEnabled: false
    },
    cleaningChange: {
        allListings: true,
        autoAdd: true,
        title: "Cleaning Change",
        message: "There was a reservation change at {{Listing Address}}. There is no cleaning on {{Previous Check-Out Date}}. The new cleaning is on {{Check-Out Date}}.",
        event: "checkoutChanged",
        lastMinuteMessageEnabled: false,
        lastMinuteMessageIsTheSame: false,
        lastMinuteMessage: "",
        reviewEnabled: false,
        reviewMessage: "",
        sendMessageAfterLeavingReview: false,
        disableMessageAfterReview: false,
        emailEnabled: true,
        email: "",
        smsEnabled: false,
        sms: "",
        delay: 0,
        preapprove: false,
        languagesEnabled: false
    },
    cleaningCancellation: {
        allListings: true,
        autoAdd: true,
        title: "Cleaning Cancellation",
        message: "A reservation was canceled at {{Listing Address}}. There is no cleaning on {{Check-Out Date}}.",
        event: "cancelled",
        lastMinuteMessageEnabled: false,
        lastMinuteMessageIsTheSame: false,
        lastMinuteMessage: "",
        reviewEnabled: false,
        reviewMessage: "",
        sendMessageAfterLeavingReview: false,
        disableMessageAfterReview: false,
        emailEnabled: true,
        email: "",
        smsEnabled: false,
        sms: "",
        delay: 0,
        preapprove: false,
        languagesEnabled: false
    },
    cleaningReminder: {
        allListings: true,
        autoAdd: true,
        title: "Cleaning Tomorrow Reminder",
        message: "This is a reminder to clean {{Listing Address}} tomorrow between {{Check-Out Time}} and {{Check-In Time}}.",
        event: "checkout",
        lastMinuteMessageEnabled: false,
        lastMinuteMessageIsTheSame: false,
        lastMinuteMessage: "",
        reviewEnabled: false,
        reviewMessage: "",
        sendMessageAfterLeavingReview: false,
        disableMessageAfterReview: false,
        emailEnabled: true,
        email: "",
        smsEnabled: false,
        sms: "",
        delay: 0,
        preapprove: false,
        languagesEnabled: false,
        days: -2,
        minutes: 1020
    }
};
