"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "textStyle", {
    enumerable: true,
    get: function() {
        return textStyle;
    }
});
const _IsWeb = require("@gluestack-ui/nativewind-utils/IsWeb");
const _tva = require("@gluestack-ui/nativewind-utils/tva");
const baseStyle = _IsWeb.isWeb ? "list-none text-start no-underline whitespace-pre-wrap word-wrap-break-word box-border" : "";
const textStyle = (0, _tva.tva)({
    base: `font-body ${baseStyle}`,
    variants: {
        variant: {
            "3xs": "text-3xs font-medium leading-sm",
            "2xs": "text-2xs font-medium leading-sm",
            xs: "text-xs font-medium leading-sm",
            sm: "text-sm font-medium leading-sm",
            md: "text-md font-medium leading-sm",
            lg: "text-lg font-medium leading-sm",
            xl: "text-xl font-medium leading-sm",
            "2xl": "text-2xl font-medium leading-sm",
            "3xl": "text-3xl font-medium leading-sm",
            "4xl": "text-4xl font-medium leading-sm",
            table: "text-xs font-semibold tracking-2xl uppercase font-paragraph"
        },
        isTruncated: {
            true: "web:truncate"
        },
        underline: {
            true: "underline"
        },
        isUnderlined: {
            true: "underline"
        },
        strikeThrough: {
            true: "line-through"
        },
        textAlign: {
            center: "text-center",
            left: "text-left",
            right: "text-right"
        },
        numberOfLines: {
            1: "line-clamp-1",
            2: "line-clamp-2"
        },
        maxW: {
            full: "max-w-full",
            "50%": "max-w-1/2"
        },
        textTransform: {
            uppercase: "uppercase",
            lowercase: "lowercase",
            capitalize: "capitalize"
        },
        isHeading: {
            true: "font-heading font-bold leading-sm text-blueGray-800"
        },
        isParagraph: {
            true: "font-paragraph font-normal leading-sm"
        },
        color: {
            "success.600": "text-success-600",
            "warning.600": "text-warning-600",
            "danger.600": "text-danger-600",
            "red.600": "text-red-600",
            "error.600": "text-error-600",
            "blueGray.400": "text-blueGray-400",
            "blueGray.600": "text-blueGray-600",
            "blueGray.800": "text-blueGray-800",
            "blue.300": "text-blue-300",
            "blue.600": "text-blue-600",
            "blue.900": "text-blue-900",
            "primary.600": "text-blue-600",
            white: "text-white",
            HomeAway: "text-HomeAway-text",
            Airbnb: "text-Airbnb-text",
            Booking: "text-Booking-text",
            Houfy: "text-Houfy-text",
            internal: "text-internal-text"
        },
        sub: {
            true: "text-xs"
        },
        italic: {
            true: "italic"
        },
        bold: {
            true: _IsWeb.isWeb ? "font-bold" : "font-body-bold"
        },
        highlight: {
            true: "bg-yellow-500"
        },
        userSelect: {
            false: "select-none"
        }
    },
    compoundVariants: [
        {
            isHeading: true,
            variant: "xs",
            className: "text-sm"
        },
        {
            isHeading: true,
            variant: "sm",
            className: "text-md"
        },
        {
            isHeading: true,
            variant: "md",
            className: "text-xl"
        },
        {
            isHeading: true,
            variant: "lg",
            className: "text-xl md:text-2xl"
        },
        {
            isHeading: true,
            variant: "xl",
            className: "text-[28px] md:text-[32px]"
        },
        {
            isHeading: true,
            variant: "2xl",
            className: "text-[32px] md:text-[36px]"
        },
        {
            isHeading: true,
            variant: "3xl",
            className: "text-[42px] md:text-[48px] tracking-xl"
        },
        {
            isHeading: true,
            variant: "4xl",
            className: "text-6xl tracking-xl"
        }
    ]
});
