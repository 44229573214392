"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "TabGroupContext", {
    enumerable: true,
    get: function() {
        return TabGroupContext;
    }
});
const _constant = require("../../../../core/src/constant");
const _react = require("react");
const TabGroupContext = (0, _react.createContext)({
    size: "sm",
    tab: undefined,
    setTab: _constant.noop
});
