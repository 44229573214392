"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
const _channel = require("../model/channel");
const getChannelLabel = (channel)=>{
    if (channel === _channel.Channel.HomeAway) {
        return "VRBO";
    }
    if (channel === _channel.Channel.ChannelConnector) {
        return "Other Channel";
    }
    return channel;
};
const _default = getChannelLabel;
