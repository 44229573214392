"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    badgeIconStyle: function() {
        return badgeIconStyle;
    },
    badgeStyle: function() {
        return badgeStyle;
    },
    badgeTextStyle: function() {
        return badgeTextStyle;
    }
});
const _tva = require("@gluestack-ui/nativewind-utils/tva");
const badgeStyle = (0, _tva.tva)({
    base: "flex-row gap-1 items-center rounded-xs data-[disabled=true]:opacity-50",
    variants: {
        action: {
            error: "",
            warning: "",
            success: "",
            info: "",
            default: ""
        },
        variant: {
            solid: "",
            outline: "border border-solid"
        },
        size: {
            sm: "px-1 py-0.5",
            md: "px-2 py-1"
        }
    },
    compoundVariants: [
        {
            action: "info",
            variant: "solid",
            class: "bg-blue-600"
        },
        {
            action: "info",
            variant: "outline",
            class: "border-blue-200 bg-info-50"
        },
        {
            action: "success",
            variant: "solid",
            class: "bg-success-600"
        },
        {
            action: "success",
            variant: "outline",
            class: "border-green-200 bg-green-50"
        },
        {
            action: "warning",
            variant: "solid",
            class: "bg-warning-600"
        },
        {
            action: "warning",
            variant: "outline",
            class: "border-warning-200 bg-warning-50"
        },
        {
            action: "error",
            variant: "solid",
            class: "bg-error-600"
        },
        {
            action: "error",
            variant: "outline",
            class: "border-danger-200 bg-error-50"
        },
        {
            action: "default",
            variant: "solid",
            class: "bg-blueGray-600"
        },
        {
            action: "default",
            variant: "outline",
            class: "border-blueGray-200 bg-blueGray-50"
        }
    ]
});
const badgeTextStyle = (0, _tva.tva)({
    base: "text-xs font-medium",
    parentVariants: {
        action: {
            error: "",
            warning: "",
            success: "",
            info: "",
            muted: ""
        },
        variant: {
            solid: "text-blueGray-50",
            outline: ""
        }
    },
    parentCompoundVariants: [
        {
            action: "info",
            variant: "outline",
            class: "text-blue-600"
        },
        {
            action: "success",
            variant: "outline",
            class: "text-success-600"
        },
        {
            action: "warning",
            variant: "outline",
            class: "text-warning-600"
        },
        {
            action: "error",
            variant: "outline",
            class: "text-error-600"
        },
        {
            action: "default",
            variant: "outline",
            class: "text-blueGray-600"
        }
    ]
});
const badgeIconStyle = (0, _tva.tva)({
    base: "w-3 h-3",
    parentVariants: {
        action: {
            error: "",
            warning: "",
            success: "",
            info: "",
            muted: ""
        },
        variant: {
            solid: "fill-blueGray-50",
            outline: ""
        }
    },
    parentCompoundVariants: [
        {
            action: "info",
            variant: "outline",
            class: "fill-blue-600"
        },
        {
            action: "success",
            variant: "outline",
            class: "fill-success-600"
        },
        {
            action: "warning",
            variant: "outline",
            class: "fill-warning-600"
        },
        {
            action: "error",
            variant: "outline",
            class: "fill-error-600"
        },
        {
            action: "default",
            variant: "outline",
            class: "fill-blueGray-600"
        }
    ]
});
