"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useChatGPT", {
    enumerable: true,
    get: function() {
        return useChatGPT;
    }
});
const _react = require("react");
const _react1 = require("../../react");
const _quickReply = require("../../services/quickReply");
function useChatGPT(reservationId) {
    const http = (0, _react.useContext)(_react1.HTTPContext);
    const [isGeneratingResponse, setIsGeneratingResponse] = (0, _react.useState)(false);
    const generateMessage = (0, _react.useCallback)(async ()=>{
        setIsGeneratingResponse(true);
        try {
            const quickReplyService = new _quickReply.QuickReplyService(http);
            const message = await quickReplyService.fetchSuggestion(reservationId);
            return message;
        } finally{
            setIsGeneratingResponse(false);
        }
    }, [
        http,
        reservationId
    ]);
    return {
        generateMessage,
        isGeneratingResponse
    };
}
