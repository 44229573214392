"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "fetchReservation", {
    enumerable: true,
    get: function() {
        return fetchReservation;
    }
});
async function fetchReservation(reservationID) {
    const url = `/reservation/${reservationID}`;
    const res = await fetch(url);
    if (res.ok) {
        const data = await res.json();
        return data;
    }
}
