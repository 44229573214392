"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    TableContext: function() {
        return TableContext;
    },
    TableHeaderFooterContext: function() {
        return TableHeaderFooterContext;
    }
});
const _react = require("react");
const TableContext = (0, _react.createContext)({
    size: "md"
});
const TableHeaderFooterContext = (0, _react.createContext)({
    type: undefined
});
