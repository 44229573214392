"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useInboxReservationData", {
    enumerable: true,
    get: function() {
        return useInboxReservationData;
    }
});
const _react = require("react");
const _react1 = require("../../react");
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
const LIMIT = 20;
const useInboxReservationData = ({ tab, initSearchText = "", onSearchTextAndTabChange })=>{
    const reservationService = (0, _react1.useReservationService)();
    const { listings } = (0, _react1.useUserContext)();
    const [sort, setSort] = (0, _react.useState)(tab === "unread" ? "recent" : tab);
    const [searchText, setSearchText] = (0, _react.useState)(initSearchText);
    const [filterOptions, setFilterOptions] = (0, _react.useState)({
        keyword: searchText,
        unread: tab === "unread" ? 1 : undefined
    });
    const reservationsAbort = (0, _react1.useAbortController)(sort, searchText);
    const selectedTab = (0, _react.useMemo)(()=>{
        if (filterOptions.unread) {
            return "unread";
        }
        if (sort === "scheduled") {
            return "scheduled";
        }
        return "recent";
    }, [
        filterOptions.unread,
        sort
    ]);
    const { data: reservations, fetchMore, refresh, hasMore, offset, loading, setData } = (0, _react1.usePaginatedFetch)((0, _react.useCallback)(async ({ offset, limit })=>{
        var _reservationsAbort_current;
        const result = await reservationService.fetchReservations(_object_spread({
            offset,
            limit,
            sortBy: sort
        }, filterOptions), {
            signal: (_reservationsAbort_current = reservationsAbort.current) === null || _reservationsAbort_current === void 0 ? void 0 : _reservationsAbort_current.signal
        });
        return result;
    }, [
        filterOptions,
        reservationService,
        reservationsAbort,
        sort
    ]), LIMIT, undefined, {
        infinite: true,
        initOnMount: false
    });
    const [reservationListings, registerUpdate, setReservationListings] = (0, _react1.useWatchReservationListings)(reservations, listings, sort === "recent" ? setData : undefined);
    (0, _react1.useDebounce)(searchText, 350, (value)=>{
        setFilterOptions((prev)=>{
            return prev.keyword === value ? prev : _object_spread_props(_object_spread({}, prev), {
                keyword: value
            });
        });
        setReservationListings(undefined);
        onSearchTextAndTabChange === null || onSearchTextAndTabChange === void 0 ? void 0 : onSearchTextAndTabChange(value, sort);
    });
    const onRead = (0, _react.useCallback)((doc)=>{
        registerUpdate(_object_spread_props(_object_spread({}, doc.raw), {
            unread: 0
        }), true);
    }, [
        registerUpdate
    ]);
    const onClearQuery = (0, _react.useCallback)(()=>{
        setSearchText("");
        setFilterOptions((prev)=>_object_spread_props(_object_spread({}, prev), {
                keyword: undefined
            }));
        onSearchTextAndTabChange === null || onSearchTextAndTabChange === void 0 ? void 0 : onSearchTextAndTabChange("", sort);
        setReservationListings(undefined);
    }, [
        onSearchTextAndTabChange,
        setReservationListings,
        sort
    ]);
    const navigateTab = (0, _react.useCallback)((tab)=>{
        const sortType = tab === "unread" ? "recent" : tab;
        setSort(sortType);
        setFilterOptions((prev)=>_object_spread_props(_object_spread({}, prev), {
                unread: tab === "unread" ? 1 : undefined
            }));
        onSearchTextAndTabChange === null || onSearchTextAndTabChange === void 0 ? void 0 : onSearchTextAndTabChange(searchText, tab);
        setReservationListings(undefined);
    }, [
        onSearchTextAndTabChange,
        searchText,
        setReservationListings
    ]);
    // effect to fetch data
    (0, _react.useEffect)(()=>{
        refresh();
    }, [
        filterOptions,
        refresh
    ]);
    return {
        reservationListings,
        reservations,
        hasMore,
        loading,
        sort,
        filterOptions,
        searchText,
        selectedTab,
        offset,
        setSearchText,
        setSort,
        fetchMore,
        refresh,
        onRead,
        onClearQuery,
        navigateTab,
        setReservationListings
    };
};
