"use strict";
const gluestackPlugin = require("@gluestack-ui/nativewind-utils/tailwind-plugin");
const { platformSelect } = require("nativewind/theme");
const { colors } = require("./colors");
// setting type for this will result in a wrong type when we import the type
/** @type {import('tailwindcss').Config} */ module.exports = {
    darkMode: process.env.DARK_MODE ? process.env.DARK_MODE : "media",
    presets: [
        require("nativewind/preset")
    ],
    safelist: [
        {
            pattern: /(bg|border|text|stroke|fill)-(primary|secondary|tertiary|error|success|warning|info|typography|outline|background)-(0|50|100|200|300|400|500|600|700|800|900|950|white|gray|black|error|warning|muted|success|info|light|dark)/
        }
    ],
    theme: {
        extend: {
            colors,
            fontFamily: {
                heading: platformSelect({
                    default: "DarkerGrotesque700Bold",
                    web: "Darker Grotesque"
                }),
                body: [
                    "InterDisplay",
                    "sans-serif"
                ],
                "body-bold": "InterDisplay-Bold",
                roboto: [
                    "Roboto",
                    "sans-serif"
                ],
                paragraph: [
                    "InterDisplay",
                    "sans-serif"
                ]
            },
            lineHeight: {
                "2xs": "1em",
                xs: "1.125em",
                sm: "1.25em",
                md: "1.375em",
                lg: "1.5em",
                xl: "1.75em",
                "2xl": "2em",
                "3xl": "2.5em",
                "4xl": "3em",
                "5xl": "4em"
            },
            fontWeight: {
                hairline: 100,
                thin: 200,
                light: 300,
                normal: 400,
                medium: 500,
                semibold: 600,
                bold: 700,
                extrabold: 800,
                black: 900,
                extraBlack: 950
            },
            fontSize: {
                "3xs": 8,
                "2xs": 10,
                xs: 12,
                sm: 14,
                md: 16,
                lg: 18,
                xl: 20,
                "2xl": 24,
                "3xl": 30,
                "4xl": 36,
                "5xl": 48,
                "6xl": 60,
                "7xl": 72,
                "8xl": 96,
                "9xl": 128
            },
            letterSpacing: {
                xs: "-0.05em",
                sm: "-0.025em",
                md: 0,
                lg: "0.025em",
                xl: "0.05em",
                "2xl": "0.1em"
            },
            boxShadow: {
                "hard-1": "-2px 2px 8px 0px rgba(38, 38, 38, 0.20)",
                "hard-2": "0px 3px 10px 0px rgba(38, 38, 38, 0.20)",
                "hard-3": "2px 2px 8px 0px rgba(38, 38, 38, 0.20)",
                "hard-4": "0px -3px 10px 0px rgba(38, 38, 38, 0.20)",
                "hard-5": "0px 2px 10px 0px rgba(38, 38, 38, 0.10)",
                "soft-1": "0px 0px 10px rgba(38, 38, 38, 0.1)",
                "soft-2": "0px 0px 20px rgba(38, 38, 38, 0.2)",
                "soft-3": "0px 0px 30px rgba(38, 38, 38, 0.1)",
                "soft-4": "0px 0px 40px rgba(38, 38, 38, 0.1)"
            },
            spacing: {
                px: "1px",
                0: "0px",
                0.5: "2px",
                1: "4px",
                1.5: "6px",
                2: "8px",
                2.5: "10px",
                3: "12px",
                3.5: "14px",
                4: "16px",
                5: "20px",
                6: 24,
                7: 28,
                8: 32,
                9: 36,
                10: 40,
                12: 48,
                16: 64,
                20: 80,
                24: 96,
                32: 128,
                40: 160,
                48: 192,
                56: 224,
                64: 256,
                72: 288,
                80: 320,
                96: 384,
                "1/2": "50%",
                "1/3": "33.333%",
                "2/3": "66.666%",
                "1/4": "25%",
                "2/4": "50%",
                "3/4": "75%",
                "1/5": "20%",
                "2/5": "40%",
                "3/5": "60%",
                "4/5": "80%",
                "1/6": "16.666%",
                "2/6": "33.333%",
                "3/6": "50%",
                "4/6": "66.666%",
                "5/6": "83.333%",
                full: "100%"
            },
            borderRadius: {
                none: 0,
                xs: 2,
                sm: 4,
                md: 6,
                lg: 8,
                xl: 12,
                "2xl": 16,
                "3xl": 24,
                full: 9999
            }
        }
    },
    plugins: [
        gluestackPlugin
    ]
};
