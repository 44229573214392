"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "iconStyle", {
    enumerable: true,
    get: function() {
        return iconStyle;
    }
});
const _tva = require("@gluestack-ui/nativewind-utils/tva");
const iconStyle = (0, _tva.tva)({
    base: "text-typography-950 fill-none stroke-transparent pointer-events-none",
    variants: {
        size: {
            "3xs": "h-2.5 w-2.5",
            "2xs": "h-3 w-3",
            xs: "h-3.5 w-3.5",
            sm: "h-4 w-4",
            md: "h-5 w-5",
            lg: "h-6 w-6"
        },
        // todoo remove as soon as fully migrated to gluestack-ui
        // for old props compatibility
        color: {
            white: "fill-white",
            "primary.600": "fill-blue-600",
            "blueGray.600": "fill-blueGray-600",
            "blueGray.400": "fill-blueGray-400",
            "blue.600": "fill-blue-600",
            "error.600": "fill-error-600",
            "red.500": "fill-red-500",
            "success.500": "fill-success-500"
        },
        fill: {
            white: "fill-white",
            "primary.600": "fill-blue-600",
            "blueGray.600": "fill-blueGray-600",
            "blueGray.400": "fill-blueGray-400",
            "blueGray.200": "fill-blueGray-200",
            "blueGray.800": "fill-blueGray-800",
            "red.500": "fill-red-500",
            "success.500": "fill-success-500"
        },
        stroke: {
            "primary.600": "stroke-blue-600",
            "blueGray.600": "stroke-blueGray-600",
            "blueGray.800": "stroke-blueGray-800"
        }
    }
});
