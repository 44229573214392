"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    formatChannelPrices: function() {
        return formatChannelPrices;
    },
    toPriceStringOnCalendar: function() {
        return toPriceStringOnCalendar;
    }
});
const _constant = require("../../../core/src/constant");
const _utilsprice = require("../../../core/src/shared/utils/utils.price");
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
const toPriceStringOnCalendar = (currencySymbol, price)=>typeof price !== "undefined" ? (0, _utilsprice.formatPrice)(price, currencySymbol) : "-";
function formatChannelPrices(price, currencySymbol) {
    return _constant.providerChannels.reduce((result, channel)=>{
        const priceChannel = price[channel];
        if (priceChannel) {
            const priceFormatted = toPriceStringOnCalendar(currencySymbol, priceChannel.price);
            const currentPriceFormatted = toPriceStringOnCalendar(currencySymbol, priceChannel.currentPrice);
            const priceOverrideFormatted = toPriceStringOnCalendar(currencySymbol, priceChannel.priceOverride);
            result[channel] = _object_spread_props(_object_spread({}, priceChannel), {
                priceFormatted,
                currentPriceFormatted,
                priceOverrideFormatted
            });
        }
        return result;
    }, _object_spread({}, price));
}
