"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useSelectedDates", {
    enumerable: true,
    get: function() {
        return useSelectedDates;
    }
});
const _reactquery = require("@tanstack/react-query");
const _react = require("react");
const _price = require("../../models/price");
const _react1 = require("../../react");
function useSelectedDates(listing, startDate, endDate) {
    const http = (0, _react.useContext)(_react1.HTTPContext);
    const { data, isLoading } = (0, _reactquery.useQuery)({
        queryKey: [
            startDate,
            endDate
        ],
        queryFn: async ()=>{
            const url = `/getCalendar/${listing._id}/${startDate}/${endDate}`;
            const { data } = await http.get(url);
            return data;
        }
    });
    return (0, _react.useMemo)(()=>{
        if (isLoading) {
            return undefined;
        }
        const raw = data === null || data === void 0 ? void 0 : data.prices;
        if (!raw) {
            return null;
        }
        const prices = raw.map((elem)=>(0, _price.formatChannelPrices)(elem, listing.currencySymbol));
        return {
            startDate: new Date(startDate),
            endDate: new Date(endDate),
            prices,
            price: prices[0]
        };
    }, [
        startDate,
        endDate,
        listing.currencySymbol,
        data,
        isLoading
    ]);
}
