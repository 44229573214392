"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    formatDate: function() {
        return formatDate;
    },
    formatDateRange: function() {
        return _frontend.formatDateRange;
    },
    formatDistanceFromNow: function() {
        return formatDistanceFromNow;
    },
    formatMMMDDYYYY: function() {
        return formatMMMDDYYYY;
    },
    formatMMMMyyyy: function() {
        return formatMMMMyyyy;
    },
    formatMMddyyyy: function() {
        return formatMMddyyyy;
    },
    formatyyyyMMdd: function() {
        return formatyyyyMMdd;
    },
    isValidDateParam: function() {
        return isValidDateParam;
    }
});
const _frontend = require("../../../../frontend/src/index");
const _datefns = require("date-fns");
function formatDistanceFromNow(timestamp) {
    let dateString = (0, _datefns.format)(timestamp, "eee, MMMM do yyyy");
    if ((0, _datefns.isToday)(timestamp)) {
        dateString = "Today";
    } else if ((0, _datefns.isTomorrow)(timestamp)) {
        dateString = "Tomorrow";
    } else if ((0, _datefns.isWithinInterval)(timestamp, {
        start: new Date(),
        end: (0, _datefns.addDays)(new Date(), 7)
    })) {
        dateString = (0, _datefns.format)(timestamp, "eeee");
    } else if ((0, _datefns.isYesterday)(timestamp)) {
        dateString = "Yesterday";
    } else if ((0, _datefns.isWithinInterval)(timestamp, {
        start: (0, _datefns.subDays)(new Date(), 7),
        end: new Date()
    })) {
        dateString = `Last ${(0, _datefns.format)(timestamp, "eeee")}`;
    }
    return dateString;
}
const formatDate = (date, formatPattern)=>{
    if (typeof date === "string") {
        return (0, _datefns.format)(new Date(date), formatPattern);
    }
    return (0, _datefns.format)(date, formatPattern);
};
const formatMMMMyyyy = (date)=>{
    return formatDate(date, "MMMM yyyy");
};
function formatMMMDDYYYY(date) {
    const formatPattern = "MMM dd, yyyy";
    return formatDate(date, formatPattern);
}
function formatyyyyMMdd(date) {
    const formatPattern = "yyyy-MM-dd";
    return formatDate(date, formatPattern);
}
function formatMMddyyyy(date) {
    const formatPattern = "MM/dd/yyyy";
    return formatDate(date, formatPattern);
}
function isValidDateParam(date) {
    return (0, _datefns.isValid)(new Date(date));
}
