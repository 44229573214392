"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useView", {
    enumerable: true,
    get: function() {
        return useView;
    }
});
const _useMobileView = require("./useMobileView");
function useView() {
    const isMobileOnWeb = (0, _useMobileView.useMobileView)();
    return process.env.IS_WEB ? isMobileOnWeb ? "webMobile" : "webDesktop" : "native";
}
