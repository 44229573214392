"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useMessageRuleDateFormatted", {
    enumerable: true,
    get: function() {
        return useMessageRuleDateFormatted;
    }
});
const _react = require("react");
const _messageRules = require("../../../utils/messageRules");
function useMessageRuleDateFormatted(rule) {
    return (0, _react.useMemo)(()=>{
        let messageRuleDateFormatted = "";
        if (rule.event === "checkin" || rule.event === "checkout" || rule.event === "occupied") {
            const { minutes } = rule;
            if (typeof minutes === "number") {
                const { time, amPM } = (0, _messageRules.formatMinutes)(minutes);
                messageRuleDateFormatted += `@${time}${amPM},`;
            }
            if (rule.event === "occupied") {
                const dayOfTheWeekFormatted = rule.dayOfTheWeek && rule.dayOfTheWeek[0].toUpperCase() + rule.dayOfTheWeek.slice(1).toLowerCase();
                messageRuleDateFormatted += ` on the first ${dayOfTheWeekFormatted}`;
            } else if (rule.days === 0) {
                messageRuleDateFormatted += ` on the day of ${rule.event}`;
            } else if (rule.days === 1) {
                messageRuleDateFormatted += ` a day after ${rule.event}`;
            } else if (rule.days && rule.days > 1) {
                messageRuleDateFormatted += ` ${Math.abs(rule.days)} days after ${rule.event}`;
            } else if (rule.days === -1) {
                messageRuleDateFormatted += ` a day before ${rule.event}`;
            } else if (typeof rule.days === "number" && rule.days < 1) {
                messageRuleDateFormatted += ` ${Math.abs(rule.days)} days before ${rule.event}`;
            }
        } else {
            if (rule.delay && rule.delay <= 60) {
                messageRuleDateFormatted += `${rule.delay} minutes after `;
            } else if (rule.delay && rule.delay <= 1440) {
                messageRuleDateFormatted += `${rule.delay / 60} hours after `;
            } else if (rule.delay && rule.delay > 1440) {
                messageRuleDateFormatted += `${rule.delay / 1440} days after `;
            } else {
                messageRuleDateFormatted += "Shortly after ";
            }
            if (rule.event === "booking") {
                messageRuleDateFormatted += `a ${rule.event}`;
            } else if (rule.event === "inquiry") {
                messageRuleDateFormatted += `an ${rule.event}`;
            } else if (rule.event === "timedout") {
                messageRuleDateFormatted += "an inquiry expires";
            } else if (rule.event === "pending") {
                messageRuleDateFormatted += "a booking request";
            } else if (rule.event === "doorUnlocked") {
                messageRuleDateFormatted += "the door was unlocked";
            } else if (rule.event === "checkinChanged") {
                messageRuleDateFormatted += "the check-in date is changed";
            } else if (rule.event === "checkoutChanged") {
                messageRuleDateFormatted += "the check-out date is changed";
            } else if (rule.event === "numberOfGuestsChanged") {
                messageRuleDateFormatted += "the number of guests changed";
            } else if (rule.event === "cancelled") {
                messageRuleDateFormatted += "a booking is canceled";
            }
        }
        return messageRuleDateFormatted;
    }, [
        rule
    ]);
}
